import { Observable, Subscription } from 'rxjs';

export class SubscriptionList {
  static new(): SubscriptionList {
    return new SubscriptionList();
  }

  static fromList(list: Subscription[]): SubscriptionList {
    return new SubscriptionList(Array.from(list));
  }

  private constructor(private readonly subscriptions: Subscription[] = []) {}

  destroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  add<T>(inputs: Observable<T>, subscriptionFunction?: (T: any) => void): void {
    this.subscriptions.push(inputs.subscribe(subscriptionFunction));
  }

  addSub(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  asList(): Subscription[] {
    return Array.from(this.subscriptions);
  }

  addAll(...subs: Subscription[]): void {
    this.subscriptions.push(...subs);
  }
}
