import { Observable, race, SchedulerLike, of } from 'rxjs';
import { delay, share, switchMap } from 'rxjs/operators';

export interface ObservableWorkMap<I, T> {
  startWork(someId: I, work: Observable<T>): void;
  getWorkResults$(someId: I): Observable<T>;
}

/**
 * Will execute the given work when there is no value in the given WorkStateMap
 * for the given ID.
 */
export function startWorkIfInitial<I, T>(
  workId: I,
  stateMap: ObservableWorkMap<I, T>,
  work: () => Observable<T>,
  scheduler?: SchedulerLike,
): Observable<T> {
  const o = workIfInitial$(workId, stateMap, work, scheduler);
  o.subscribe();
  return o;
}

/**
 * Will execute the given work when the following conditions are true:
 * - There is no value in the given WorkStateMap for the given ID
 * - A subscriber has been added to the observable returned from this function
 */
export function startWorkIfNeededForSubscriber<I, T>(
  workID: I,
  stateMap: ObservableWorkMap<I, T>,
  work: () => Observable<T>,
  scheduler?: SchedulerLike,
): Observable<T> {
  return of('').pipe(
    switchMap(() => {
      // SwitchMap causes this work to only happen after a subscriber is added
      return workIfInitial$(workID, stateMap, work, scheduler);
    }),
  );
}

function workIfInitial$<I, T>(
  workId: I,
  stateMap: ObservableWorkMap<I, T>,
  work: () => Observable<T>,
  scheduler?: SchedulerLike,
): Observable<T> {
  const workWithDelay: Observable<T> = of<I>(workId).pipe(
    delay<I>(10, scheduler),
    switchMap((id: I) => {
      stateMap.startWork(id, work());
      return stateMap.getWorkResults$(workId);
    }),
    share<T>(),
  );
  return race(workWithDelay, stateMap.getWorkResults$(workId));
}

// This function can be called to immediate store a result in a work state map.
// Useful if you have already run the work, but just want to publish the result
// to anyone who is subscribed to the given stateMap's workResults.
export function applyFinishedWork<ID, RESULT>(stateMap: ObservableWorkMap<ID, RESULT>, id: ID, result: RESULT): void {
  stateMap.startWork(id, of(result));
}
