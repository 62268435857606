import { EMPTY, merge, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, mapTo, share, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { WorkStates } from './work-states';

export class SwitchingWorkState<INPUT, OUTPUT> implements WorkStates<OUTPUT> {
  readonly workResults$: Observable<OUTPUT>;
  readonly isLoading$: Observable<boolean>;
  readonly isSuccess$: Observable<boolean>;
  private readonly successEvents$$ = new Subject<boolean>();
  readonly successEvents$: Observable<boolean> = this.successEvents$$.pipe(share());
  private readonly inputs$$ = new ReplaySubject<INPUT>(1);
  readonly subscriptions: Subscription[] = [];

  constructor(private inputHandler: (i: INPUT) => Observable<OUTPUT>, logErrors = true) {
    const errors$ = new ReplaySubject<Error>(1);
    if (logErrors) {
      this.subscriptions.push(errors$.subscribe(console.error));
    }
    this.workResults$ = this.inputs$$.pipe(
      switchMap((i: INPUT) =>
        this.inputHandler(i).pipe(
          catchError((err) => {
            errors$.next(err);
            return EMPTY;
          }),
        ),
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.isLoading$ = merge(
      errors$.pipe(mapTo(false)),
      this.inputs$$.pipe(mapTo(true)),
      this.workResults$.pipe(mapTo(false)),
    ).pipe(startWith(false), distinctUntilChanged());
    const uniqueSuccess$ = merge(this.workResults$.pipe(mapTo(true)), errors$.pipe(mapTo(false)));
    this.isSuccess$ = uniqueSuccess$.pipe(distinctUntilChanged());
    this.subscriptions.push(uniqueSuccess$.subscribe(this.successEvents$$));
  }

  updateInputHandler(inputHandler: (i: INPUT) => Observable<OUTPUT>): void {
    this.inputHandler = inputHandler;
  }

  startWork(param: INPUT): void {
    this.inputs$$.next(param);
  }

  destroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
